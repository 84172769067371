.caterpillarContainer{

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
    overflow: visible;

    svg, svg *{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left:0;
    }
    
    .caterpillar, .caterpillar *{
        // display: none; 
        fill: white;
        stroke: white;
    }

    .butterfly, .butterfly {
        width: 100%;
        height: 100%;
        path{
 
            fill:  black;
            
        }
        opacity: 0;
        transform: scale(1.5);
        z-index: 9999999999999999999;
    }

} 